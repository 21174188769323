import { Carousel } from "@mantine/carousel";
import "@mantine/carousel/styles.css";
import styles from "./lego.module.css";

interface ListingCardCarouselItems {
    listingResult: any[];
    createFunction: Function;
}
export default function ListingCardCarousel(props: ListingCardCarouselItems) {
    return (
        <Carousel
            slideGap="sm"
            align="start"
            slideSize="20%"
            slidesToScroll={1}
            loop
            classNames={{
                container: styles.carouselContainer,
            }}
        >
            {props.listingResult?.map((item, index) => (
                <Carousel.Slide key={index}>
                    {props.createFunction(item)}
                </Carousel.Slide>
            ))}
        </Carousel>
    );
}
